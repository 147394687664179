import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';

import mergeProps from 'lib/utils/mergeProps';
import { useCallback, useState } from 'react';
import { useJWPlayer } from './JWPlayerContext';
import { VIDEO_ORIENTATION_CLASS } from './constants';
import { AdEnd, AdStart, Play, PlaylistItem } from './constants/playEvents';
import { useNextRecommendedVideo } from './hooks/useNextRecommendedVideo';
import { usePlayerEvent } from './hooks/usePlayerEvent';
import { addCaptions } from './utils/addCaptions';

export interface VideoArticleContainerProps extends ComponentProps<'div'> {
  showNextVideoPopup: boolean;
  verticalFloatingMode?: boolean;
  aspectRatio?: string;
  setShowNextVideoPopup: (show: boolean) => void;
}

export const VideoArticleContainerComponent: Component<VideoArticleContainerProps> = ({
  showNextVideoPopup,
  verticalFloatingMode,
  setShowNextVideoPopup,
  children,
  aspectRatio,
  ...props
}) => {
  const [jwClassName, setJwClassName] = useState<string[]>([]);
  const { player, currentMediaAspectRatio } = useJWPlayer();

  const [isMiniStickyActive, setMiniStickyActive] = useState(true);

  const className = useClassName('container', props, {
    className: [
      verticalFloatingMode && VIDEO_ORIENTATION_CLASS[currentMediaAspectRatio],
      jwClassName,
      isMiniStickyActive && 'jw-flag-mini-sticky',
      'cts-impression-group',
    ],
  });

  const addjwClassName = useCallback((mode: string) => {
    setJwClassName((prev) => (prev.includes(mode) ? prev : [...prev, mode]));
  }, []);

  const removejwClassName = useCallback((mode: string) => {
    setJwClassName((prev) => prev.filter((m: string) => m !== mode));
  }, []);

  // Next Recommended Video Fetaure
  useNextRecommendedVideo(setShowNextVideoPopup);

  const onPlayListItem = useCallback(
    ({ item }: { item: any }) => {
      if (isMiniStickyActive) {
        if (item.video_advertiser) {
          setMiniStickyActive(false);
        }
      }

      const { tracks, pubdate } = item;
      addCaptions(player, {
        tracks,
        publishedAt: new Date(pubdate * 1000).toISOString()?.split('T')[0] || '',
        aspectRatio: aspectRatio || '16:9',
      });
    },
    [isMiniStickyActive, player, aspectRatio],
  );

  const onPlay = useCallback(() => {
    addjwClassName('jw-playing');
  }, [addjwClassName]);

  const onAdBreakStart = useCallback(() => {
    addjwClassName('jw-ad-active');
  }, [addjwClassName]);

  const onAdBreakEnd = useCallback(() => {
    removejwClassName('jw-ad-active');
  }, [removejwClassName]);

  usePlayerEvent(player, Play, onPlay);
  usePlayerEvent(player, AdStart, onAdBreakStart);
  usePlayerEvent(player, AdEnd, onAdBreakEnd);
  usePlayerEvent(player, PlaylistItem, onPlayListItem);

  const isNextVideoPopupHidden = jwClassName.includes('js_hide-next-popup');
  if (showNextVideoPopup && isNextVideoPopupHidden) {
    removejwClassName('js_hide-next-popup');
  } else if (!showNextVideoPopup && !isNextVideoPopupHidden) {
    addjwClassName('js_hide-next-popup');
  }

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
